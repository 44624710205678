import { AbstractDataServiceAdapter, JsonResultsAdapter, MetadataStore, config, AutoGeneratedKeyType } from 'breeze-client';

// import { JsonResultsAdapter, KeyMapping } from './breeze';
/** @hidden */
class DataServiceWebApiAdapter extends AbstractDataServiceAdapter {
  constructor() {
    super();
    this.jsonResultsAdapter = new JsonResultsAdapter({
      name: "webApi_default",
      visitNode: function (node, mappingContext, nodeContext) {
        if (node == null) return {};
        let entityTypeName = MetadataStore.normalizeTypeName(node.$type);
        let entityType = entityTypeName && mappingContext.entityManager.metadataStore.getEntityType(entityTypeName, true);
        let propertyName = nodeContext.propertyName;
        let ignore = propertyName && propertyName.substr(0, 1) === "$";
        return {
          entityType: entityType,
          nodeId: node.$id,
          nodeRefId: node.$ref,
          ignore: ignore
        };
      }
    });
    this.name = "webApi";
  }
  static register(config$1) {
    config$1 = config$1 || config;
    config$1.registerAdapter("dataService", DataServiceWebApiAdapter);
    return config$1.initializeAdapterInstance("dataService", "webApi", true);
  }
  /** @hidden @internal */
  _prepareSaveBundle(saveContext, saveBundle) {
    let changeRequestInterceptor = this._createChangeRequestInterceptor(saveContext, saveBundle);
    let em = saveContext.entityManager;
    let metadataStore = em.metadataStore;
    let helper = em.helper;
    let serSaveBundle = {};
    serSaveBundle.entities = saveBundle.entities.map(function (e, ix) {
      let rawEntity = helper.unwrapInstance(e);
      let autoGeneratedKey;
      if (e.entityType.autoGeneratedKeyType !== AutoGeneratedKeyType.None) {
        autoGeneratedKey = {
          propertyName: e.entityType.keyProperties[0].nameOnServer,
          autoGeneratedKeyType: e.entityType.autoGeneratedKeyType.name
        };
      }
      let originalValuesOnServer = helper.unwrapOriginalValues(e, metadataStore);
      rawEntity.entityAspect = {
        entityTypeName: e.entityType.name,
        defaultResourceName: e.entityType.defaultResourceName,
        entityState: e.entityAspect.entityState.name,
        originalValuesMap: originalValuesOnServer,
        autoGeneratedKey: autoGeneratedKey
      };
      rawEntity = changeRequestInterceptor.getRequest(rawEntity, e, ix);
      return rawEntity;
    });
    serSaveBundle.saveOptions = {
      tag: saveBundle.saveOptions.tag
    };
    changeRequestInterceptor.done(serSaveBundle.entities);
    return serSaveBundle;
  }
  /** @hidden @internal */
  _prepareSaveResult(saveContext, data) {
    // use the jsonResultAdapter to extractResults and extractKeyMappings
    let jra = saveContext.dataService.jsonResultsAdapter || this.jsonResultsAdapter;
    let entities = jra.extractSaveResults(data) || [];
    let keyMappings = jra.extractKeyMappings(data) || [];
    let deletedKeys = jra.extractDeletedKeys ? jra.extractDeletedKeys(data) || [] : [];
    if (keyMappings.length) {
      // HACK: need to change the 'case' of properties in the saveResult
      // but KeyMapping properties internally are still ucase. ugh...
      keyMappings = keyMappings.map(function (km) {
        if (km.entityTypeName) return km; // it's already lower case
        let kmHack = km;
        let entityTypeName = MetadataStore.normalizeTypeName(kmHack.EntityTypeName);
        return {
          entityTypeName: entityTypeName,
          tempValue: kmHack.TempValue,
          realValue: kmHack.RealValue
        };
      });
    }
    if (deletedKeys.length) {
      deletedKeys = deletedKeys.map(function (dk) {
        if (dk.entityTypeName) return dk; // it's already lower case
        let entityTypeName = MetadataStore.normalizeTypeName(dk.EntityTypeName);
        // NOTE the dk.KeyValue => keyValues transition - needed because we are deserializing an .NET EntityKey
        return {
          entityTypeName: entityTypeName,
          keyValues: dk.KeyValue
        };
      });
    }
    return {
      entities: entities,
      keyMappings: keyMappings,
      deletedKeys: deletedKeys
    };
  }
}
config.registerAdapter("dataService", DataServiceWebApiAdapter);

/**
 * Generated bundle index. Do not edit.
 */

export { DataServiceWebApiAdapter };
