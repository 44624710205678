import { config, EntityType } from 'breeze-client';

/** Appends parameter to url, with appropriate delimiter */
function appendQueryStringParameter(url, parameter) {
  if (!parameter) return url;
  let separator = url.endsWith("?") ? "" : url.includes("?") ? "&" : "?";
  return url + separator + parameter;
}
/** Encodes the object into query string parameters */
function encodeParams(obj) {
  let query = '';
  let subValue, innerObj, fullSubName;
  for (let name in obj) {
    if (!obj.hasOwnProperty(name)) {
      continue;
    }
    let value = obj[name];
    if (value instanceof Array) {
      for (let i = 0; i < value.length; ++i) {
        subValue = value[i];
        fullSubName = name + '[' + i + ']';
        innerObj = {};
        innerObj[fullSubName] = subValue;
        query += encodeParams(innerObj) + '&';
      }
    } else if (value && value.toISOString) {
      // a feature of Date-like things
      query += encodeURIComponent(name) + '=' + encodeURIComponent(value.toISOString()) + '&';
    } else if (value instanceof Object) {
      for (let subName in value) {
        if (obj.hasOwnProperty(name)) {
          subValue = value[subName];
          fullSubName = name + '[' + subName + ']';
          innerObj = {};
          innerObj[fullSubName] = subValue;
          query += encodeParams(innerObj) + '&';
        }
      }
    } else if (value === null) {
      query += encodeURIComponent(name) + '=&';
    } else if (value !== undefined) {
      query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
    }
  }
  return query.length ? query.substr(0, query.length - 1) : query;
}
class UriBuilderJsonAdapter {
  constructor() {
    this.name = "json";
  }
  static register(config$1) {
    config$1 = config$1 || config;
    config$1.registerAdapter("uriBuilder", UriBuilderJsonAdapter);
    return config$1.initializeAdapterInstance("uriBuilder", "json", true);
  }
  initialize() {}
  buildUri(entityQuery, metadataStore) {
    // force entityType validation;
    let entityType = entityQuery._getFromEntityType(metadataStore, false);
    if (!entityType) entityType = new EntityType(metadataStore);
    let json = entityQuery.toJSONExt({
      entityType: entityType,
      toNameOnServer: true
    });
    json.from = undefined;
    json.queryOptions = undefined;
    if (json.parameters && json.parameters.$data) {
      // remove parameters if doing ajax post
      json.parameters = undefined;
    }
    let jsonString = JSON.stringify(json);
    if (jsonString.length > 2) {
      let urlBody = encodeURIComponent(jsonString);
      return appendQueryStringParameter(entityQuery.resourceName, urlBody);
    } else {
      return entityQuery.resourceName;
    }
  }
}
config.registerAdapter("uriBuilder", UriBuilderJsonAdapter);

/**
 * Generated bundle index. Do not edit.
 */

export { UriBuilderJsonAdapter };
